import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe className="framed" src="https://nutricia.com.au/souvenaid/mild-cognitive-impairment/?utm_source=lets-chat-mci&utm_medium=referral&utm_campaign=lets-chat-mci" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
    </div>
  );
}

export default App;
